<template>
  <FormulateInput
    class="lang-multiselect"
    v-model="model"
    :label="$t('components.select_language')"
    :placeholder="$t('components.select_language')"
    type="multi-select"
    variant="alt"
    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
    :options="computedOptions"
    searchable
    show-icon
    :config="{
      label: 'name',
      trackBy: 'id',
    }"
  />
</template>

<script>
// TODO find and use the relevant npm for lang flags instead of custom workaround
import CountryFlag from 'vue-country-flag'
import { getCountryCodeByLanguage } from "@shared/plugins/i18n/utils";
import {uniqBy} from "lodash";

export default {
  name: "language-multiselect",

  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    model: {
      get() {
        // eslint-disable-next-line no-unused-vars
        // const { icon, ...value } = (this.currentLang || this.defaultLang);
        // return value;
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },

    // TODO add flag icons
    computedOptions() {
      return uniqBy(this.value.concat(this.options), "id")
        .map((el) => {
          const countryCode = getCountryCodeByLanguage(el.code);
          return {
            ...el,
            icon: countryCode ? {
              functional: true,
              render: (h) =>
                h("div", { class: "flag-container" }, [
                  h(CountryFlag, { props: { country: countryCode } })
                ])
            } : null,
          }
        })
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/functions.scss";

.lang-multiselect {
  &__icon {
    @apply text-base-content;
  }
  ::v-deep {
    .multiselect {
      &__tags {
        font-weight: normal;
        text-transform: capitalize;
        &:hover {
          box-shadow: none;
        }
        svg {
          fill: var(--theme-color-base-content) !important;
        }
      }
      &__option {
        min-height: toRem(34px);
        padding-block: 0;
        &--highlight {
          border: none !important;
        }
      }
      &__content-wrapper {
        max-width: toRem(250px);
        width: auto;
        border-radius: toRem(8px);
      }
    }
    .flag-container {
      display: inline-flex;
      align-items: center;
      padding-bottom: toRem(9px);
    }
  }
}
</style>